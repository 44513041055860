<template>
    <tiptap-dropdown
        #default="{ media, hide }"
        extension="media"
        v-bind="dropdownProps"
        menu-class="image-menu"
        right
    >
        <b-tabs
            class="pr-3 pl-3 pb-2"
            nav-class="mb-3"
            small
            lazy
            :fill="!!media.uploadHandler"
        >
            <b-tab :title="$t('TIPTAP.MEDIA.BY_URL')">
                <validation-observer #default="{ invalid, valid }">
                    <input-field
                        v-model="linkModel"
                        class="mb-0"
                        rules="url"
                        placeholder="https://"
                        :disabled="busy"
                        :description="invalid ? null : $t('TERMS.ENTER_SAVE')"
                        @enter="onEnterLink(valid, hide)"
                    />
                </validation-observer>
            </b-tab>
            <b-tab
                v-if="media.uploadHandler"
                :title="$t('TIPTAP.MEDIA.UPLOAD')"
            >
                <spinner
                    spinner-class="py-5"
                    :loading="busy"
                >
                    <dropzone
                        :rules="{
                            ext: supportedExt,
                            size: 10240
                        }"
                        hide-file-list
                        @input="onFileDrop($event, hide)"
                    />
                </spinner>
            </b-tab>
        </b-tabs>
    </tiptap-dropdown>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { BTabs, BTab } from 'bootstrap-vue'
    import TiptapDropdown from './TiptapDropdown.vue'
    import { Inject } from './TiptapEditorProvider.vue'
    import InputField from '@common/Forms/InputField.vue'
    import Dropzone from '@common/components/Dropzone.vue'
    import Spinner from '@common/components/Spinner.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        components: {
            BTabs,
            BTab,
            TiptapDropdown,
            InputField,
            Dropzone,
            Spinner,
        },

        inject: ['editor', 'extensions'],

        data() {
            return {
                linkModel: '',
                imgExts: ['jpg', 'jpeg', 'png', 'gif', 'svg', 'webp'],
                videoExts: ['mp4', 'webm', 'ogg', 'avi', 'mov', 'mkv'],
                busy: false,
            }
        },

        computed: {
            canImage(): boolean {
                return this.extensions.getOptions('media').image
            },

            canVideo(): boolean {
                return this.extensions.getOptions('media').video
            },

            allowedMediaTypes(): string[] {
                const types = []
                this.canImage && types.push('image')
                this.canVideo && types.push('video')

                return types
            },

            supportedExt(): string[] {
                const ext = []
                this.canImage && ext.push(...this.imgExts)
                this.canVideo && ext.push(...this.videoExts)

                return ext
            },

            dropdownProps(): { icon: string; label: string } {
                if (this.canImage && this.canVideo) return {
                    icon: 'photo-video',
                    label: 'Image & Video',
                }
                else if (this.canImage) return {
                    icon: 'image',
                    label: 'Image',
                }
                else return {
                    icon: 'film',
                    label: 'Video',
                }
            },
        },

        methods: {
            async determineMediaType(url: string): Promise<string> {
                // Define extensions for image and video
                const imageExtensions = this.imgExts.map((item) => `.${item}`)
                const videoExtensions = this.videoExts.map((item) => `.${item}`)

                // Check file extension
                const isImage = imageExtensions.some(ext => url.toLowerCase().endsWith(ext))
                const isVideo = videoExtensions.some(ext => url.toLowerCase().endsWith(ext))

                if (isImage) return 'image'
                if (isVideo) return 'video'

                // If extension is not conclusive, use fetch to check Content-Type
                try {
                    const response = await fetch(url, { method: 'HEAD' })
                    const contentType = response.headers.get('Content-Type')

                    if (contentType?.startsWith('image/')) return 'image'
                    if (contentType?.startsWith('video/')) return 'video'

                    return 'unknown'
                } catch (error) {
                    console.error('Error fetching the URL:', error)

                    return 'unknown'
                }
            },

            async onEnterLink(valid: boolean, hide: () => void): Promise<void> {
                if (!valid || !this.linkModel.length) return

                const linkType = await this.determineMediaType(this.linkModel)

                if (!this.allowedMediaTypes.includes(linkType)) {
                    return
                }

                this.editor.chain().setMedia({
                    type: linkType as 'video' | 'image',
                    src: this.linkModel,
                })
                    .focus()
                    .run()

                hide()
                this.linkModel = ''
            },

            async onFileDrop(files: File[], hide: () => void): Promise<void> {
                const file = files[0]
                let type = 'unknown'

                if (file.type.startsWith('image/'))
                    type = 'image'
                if (file.type.startsWith('video/'))
                    type = 'video'

                if (!this.allowedMediaTypes.includes(type)) {
                    return
                }

                this.busy = true

                try {
                    const src = await this.extensions.getOptions('media').uploadHandler(files[0])

                    this.editor.chain().setMedia({
                        type: type as 'video' | 'image',
                        src: src,
                    })
                        .focus()
                        .run()
                    hide()
                } catch (e) {
                    console.error(e)
                    this.$flash('danger', this.$t('ERROR.SOMETHING_WENT_WRONG'))
                } finally {
                    this.busy = false
                }
            },
        },
    })
</script>

<style lang="scss" scoped>
    ::v-deep(.image-menu) {
        min-width: 20rem;
    }
</style>