<template>
    <validation-provider
        #default="props"
        :vid="vid"
        :name="nameLocal"
        :rules="rules"
        :custom-messages="customMessages"
        :debounce="availableRules.includes('remote') ? 1500 : 0"
        :immediate="immediate"
        :skip-if-empty="skipIfEmpty"
        slim
    >
        <b-form-group
            class="rich-text-field"
            :label="label"
            :label-class="labelClasses"
            :label-for="nameLocal"
            :description="description"
            :disabled="disabled"
            :invalid-feedback="getValidationError(props.errors)"
            :state="getValidationState(props)"
        >
            <tiptap-editor
                v-model="internalValue"
                :content-style="{ minHeight, maxHeight }"
                :class="inputClass"
                :disabled="disabled"
                :options="optionsLocal"
                v-bind="$attrs"
            >
                <template
                    v-for="(_, slot) of $scopedSlots"
                    #[slot]="scope"
                >
                    <slot
                        :name="slot"
                        v-bind="scope"
                    />
                </template>
            </tiptap-editor>
        </b-form-group>
    </validation-provider>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import SharedField from './mixin'
    import MaxLengthMixin from './maxlength.mixin'
    import { TiptapEditor, TiptapOptions } from '@common/RichText/Tiptap'
    import { buildFullPath } from '@common/Resources/utils'
    /**
     * Rich text field component.
     *
     * - Supports s3 uploads on images and videos using `uploadOptions` prop
     * - Keeps track of image/video removals from our s3 bucket. Once you fire
     *   save contnet from any parent you can use the public method `cleanupMedia`
     *   from parent via $refs to remove any s3 assets that were marked for deletion.
     */
    export default (Vue as VueConstructor<Vue
        & InstanceType<typeof SharedField>
        & InstanceType<typeof MaxLengthMixin>
    >).extend({
        components: { TiptapEditor },
        mixins: [SharedField, MaxLengthMixin],
        props: {
            /**
             * Minimum height of text field
             */
            minHeight: {
                type: String as PropType<string>,
                default: '300px',
            },
            /**
             * Minimum height of text field
             */
            maxHeight: {
                type: String as PropType<string>,
                default: '600px',
            },
            /**
             * Editor component configuration object
             */
            options: {
                type: Object as PropType<TiptapOptions>,
                default: (): object => ({}),
            },

            /**
             * Upload options for s3, this will activate image & video uploaing
             * capabilities on the text editor.
             */
            uploadOptions: {
                type: Object as PropType<ResourceLocation>,
                default: null,
                validator: ({ folder, id }): boolean =>
                    !!folder && !!id,
            },

        },

        data() {
            return {
                s3DeletionQueue: [] as string[],
            }
        },

        computed: {
            optionsLocal(): TiptapOptions {
                const options: TiptapOptions = { ...this.options }

                if (this.placeholder)
                    options.placeholder = this.placeholder

                if (options.media) {
                    options.media = {
                        onMediaRemoved: (src) => this.s3DeletionQueue.push(src),
                        ...(!!this.uploadOptions && {
                            uploadHandler: (file) => this.uploadHandler(file),
                        }),
                        ...(typeof options.media === 'object' ? options.media : {}),
                    }
                }

                return options
            },
        },

        methods: {
            async uploadHandler(file: File): Promise<string> {
                const { key } = await this.$store.dispatch('Resource/uploadFile', {
                    file,
                    location: this.uploadOptions,
                })

                return buildFullPath(key) ?? ''
            },

            /**
             * Clean up s3 keys that have been marked for deletion
             * This should be fired **AFTER** the content of this editor
             * has been saved in parent.
             *
             * @public
             */
            async cleanupMedia(): Promise<void> {
                const publicBucket = LB_ENV.common.s3.buckets.public.url
                const privateBucket = LB_ENV.resourceCloudFrontBaseUrl

                if (!this.s3DeletionQueue.length)
                    return

                const promises = this.s3DeletionQueue.map((path) => {
                    if (path.includes(publicBucket)) {
                        const key = path.replace(publicBucket, '').replace(/^\//g, '')

                        return this.$store.dispatch('Resource/deletePublicKey', key)
                    } else if (path.includes(privateBucket)) {
                        const key = path.replace(privateBucket, '').replace(/^\//g, '')

                        return this.$store.dispatch('Resource/deleteFile', key)
                    } else return null
                }).filter((promise) => promise instanceof Promise)

                await Promise.allSettled(promises)
            },
        },
    })
</script>
