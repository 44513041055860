<template>
    <label
        class="checkmark-field"
        :class="[`checkmark-field--${type}`, {
            'pointer': !disabled
        }]"
    >
        <input
            class="checkmark-field__input"
            type="checkbox"
            :checked="isChecked"
            :disabled="disabled"
            :value="value"
            @change="updateInput"
        >
        <span
            class="checkmark-field__checkmark"
            :class="{ 'checkmark-field__checkmark__checked': isChecked }"
        >
            <fa
                v-if="!noIcon"
                :transform="iconTransform"
                :icon="icon"
            />
        </span>
        <span
            v-if="hasLabel"
            class="checkmark-field__label"
            :class="labelClass"
        >
            <!--
                @slot Label content - overwrites label prop!
                @binding {boolean} isChecked the state of the checkmark field
            -->
            <slot
                name="label"
                :is-checked="isChecked"
            >
                {{ label }}
            </slot>
        </span>
    </label>
</template>

<script lang="ts">
    import { isEqual } from 'lodash'
    import Vue, { PropType } from 'vue'

    /**
     * Custom checkmark field
     *
     * This field works essentially as a checkbox input field, but with custom look.
     * Additionally it can render as a radio button as well, but still keeping its
     * 'checkboxness'.
     *
     * **Notable features**
     *
     * - When you pass the same array to the v-model on multiple checkboxes then
     *   you can collect values from checked boxes, using the `value` prop.
     *   unchecking will remove them from the array.
     * - You have 2 props available for you to set the v-model state depending
     *   if box is checked or not. (`checkedValue` and `uncheckedValue`)
     * - You can change the icon displayed in the field with the `icon` property
     * - You can display label as a slot if you need more then a simple string.
     *
     * @example ./__docs__/CheckmarkField.examples.md
     */
    export default Vue.extend({
        model: {
            prop: 'modelValue',
            event: 'change',
        },
        props: {
            /**
             * @model
             */
            modelValue: {
                type: [Boolean, Number, String, Array] as PropType<boolean | number | string | any[]>,
                default: null,
            },
            /**
             * Value to toggle when model is an array
             */
            value: {
                type: [String, Number] as PropType<string | number>,
                default: null,
            },
            /**
             * Optional label to place next to checkfield.
             */
            label: {
                type: String,
                default: '',
            },
            /**
             * Optional label classes.
             */
            labelClass: {
                type: String as PropType<string>,
                default: '',
            },
            /**
             * Value returned when this field is checked
             */
            checkedValue: {
                type: [Boolean, Number, String, Object] as PropType<boolean | number | string | object>,
                default: true,
            },
            /**
             * Value returned when this field is unchecked
             */
            uncheckedValue: {
                type: [Boolean, Number, String, Object] as PropType<boolean | number | string | object>,
                default: false,
            },
            /**
             * Display type, round as radio or square as checkbox
             */
            type: {
                type: String as PropType<string>,
                default: 'checkbox',
                validator: (value: string): boolean => ['checkbox', 'radio'].includes(value),
            },
            /**
             * Icon to be displayed inside the checkmark field
             */
            icon: {
                type: [Array, String] as PropType<string[] | string>,
                default: (): string[] => ['fas', 'check'],
            },
            /**
             * Icon transform string
             */
            iconTransform: {
                type: String as PropType<string>,
                default: null,
            },
            /**
             * Do not show icon when unchecked
             */
            noIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Disable the checkmark field
             */
            disabled: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },
        computed: {
            isChecked(): boolean {
                if (this.modelValue instanceof Array) {
                    return this.modelValue.includes(this.value)
                }

                return isEqual(this.modelValue, this.checkedValue)
            },

            hasLabel(): boolean {
                return !!this.label || !!this.$slots['label'] || !!this.$scopedSlots['label']
            },
        },
        methods: {
            updateInput(event: HTMLInputEvent): void {
                const isChecked = event.target.checked

                if (this.modelValue instanceof Array) {
                    const newValue = [...this.modelValue]
                    if (isChecked) {
                        newValue.push(this.value)
                    } else {
                        newValue.splice(newValue.indexOf(this.value), 1)
                    }
                    this.$emit('change', newValue)
                } else {
                    this.$emit('change', isChecked ? this.checkedValue : this.uncheckedValue)
                }
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';


    .checkmark-field {
        $root: &;
        user-select: none;
        display: flex;
        align-items: center;
        margin: 0;

        &__input {
            position: absolute;
            opacity: 0;
            height: 0;
            width: 0;
        }

        &__checkmark {
            display: flex;
            justify-content: center;
            align-items: center;
            border: $border-width solid var(--checkmark-border, $gray-400);
            border-radius: $border-radius;
            background: var(--checkmark-bg, $white);
            color: var(--checkmark-color, $gray-400);
            height: calc(1.5rem + 1px);
            width: calc(1.5rem + 1px);

            &__checked {
                background: var(--checkmark-bg-checked, whitelabel-color('primary'));
                color: var(--checkmark-color-checked, whitelabel-color('primary-txt'));
                border-color: var(--checkmark-border-checked, whitelabel-color('primary'));
            }
        }

        &__label {
            margin-left: spacer(3);
        }

        // Radio type, make checkmark round.
        &--radio &__checkmark {
            border-radius: 100%;
        }
    }
</style>
