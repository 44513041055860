<template>
    <fa-layers
        v-if="icon"
        fixed-width
    >
        <fa
            fixed-width
            :icon="icon"
        />
        <fa
            :icon="['fas', 'asterisk']"
            class="text-danger"
            transform="shrink-7 up-6 right-14"
        />
    </fa-layers>

    <fa
        v-else
        :icon="['fas', 'asterisk']"
        class="text-danger"
        size="xs"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'

    export default Vue.extend({
        props: {
            icon: {
                type: [String, Array] as PropType<string | string[]>,
                default: null,
            },
        },
    })
</script>
