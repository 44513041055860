<template>
    <b-dropdown-item
        v-if="tag === 'dropdown'"
        :href="url"
        target="_blank"
    >
        <fa
            v-if="!noIcon"
            :icon="['fal', 'fa-download']"
        />
        <span
            v-if="$attrs.label"
            v-text="$attrs.label"
        />
    </b-dropdown-item>

    <btn
        v-else
        :tag="tag"
        :href="url"
        target="_blank"
        download
        v-bind="{
            icon: noIcon ? null : ['fal', 'download'],
            ...$attrs,
        }"
    />
</template>

<script lang="ts">
    import Vue, { PropType } from 'vue'
    import { BDropdownItem } from 'bootstrap-vue'
    import { buildFullPath } from '../utils'

    export default Vue.extend({
        components: {
            BDropdownItem,
        },

        props: {
            path: {
                type: String as PropType<string>,
                required: true,
            },

            noIcon: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /** Specify the HTML tag to render instead of the default tag */
            tag: {
                type: String as PropType<string>,
                default: null,
            },

            public: {
                type: Boolean as PropType<boolean>,
                default: false,
            },
        },

        computed: {
            url(): string | null {
                return buildFullPath(this.path, this.public)
            },

            variant(): string | null {
                return this.tag === 'a' ? 'link' : 'default'
            },
        },
    })
</script>
