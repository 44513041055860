import { mergeAttributes, nodeInputRule, Node } from '@tiptap/core'
import { TagParseRule } from '@tiptap/pm/model'
import { VueNodeViewRenderer } from '@tiptap/vue-2'
import NodeViewImage from '../components/NodeView/NodeViewImage.vue'

export interface MediaOptions {
    /**
   * HTML attributes to add to the media element.
   */
    HTMLAttributes: Record<string, any>,

    /**
     * Upload handler that returns a string for video/image src
     *
     * @param File
     */
    uploadHandler?: (file: File) => Promise<string>;

    /**
     * Media removed event for each media element that is removed from editor
     * @param src
     */
    onMediaRemoved?: (src: string) => void;

    /**
     * Support images
     */
    image: boolean;

    /**
     * Support video
     */
    video: boolean;
}

declare module '@tiptap/core' {
    interface Commands<ReturnType> {
        media: {
            /**
             * Add a media element
             * @param options The image attributes
             */
            setMedia: (options: {
                type: 'image' | 'video' | null,
                src: string,
                alt?: string,
                title?: string
            }) => ReturnType,
        }
    }
}

const enum ImageDisplay {
    INLINE = 'inline',
    BREAK_TEXT = 'block',
    FLOAT_LEFT = 'left',
    FLOAT_RIGHT = 'right',
}

const IMAGE_INPUT_REGEX = /(?:^|\s)(!\[(.+|:?)]\((\S+)(?:(?:\s+)["'](\S+)["'])?\))$/

export default Node.create<MediaOptions>({
    name: 'media',
    inline: true,
    group: 'inline',
    draggable: true,

    addOptions() {
        return {
            HTMLAttributes: {},
            uploadHandler: undefined,
            onMediaRemoved: undefined,
            image: true,
            video: true,
        }
    },

    addAttributes() {
        return {
            type: {
                default: null,
                parseHTML: (element) => element.getAttribute('data-type'),
                renderHTML: (attributes) => ({
                    ['data-type']: attributes.type,
                }),
            },
            src: {
                default: null,
            },
            alt: {
                default: null,
            },
            title: {
                default: null,
            },
            width: {
                default: null,
                parseHTML: (element) => {
                    const width = element.style.width || element.getAttribute('width') || null

                    return width === null ? null : parseInt(width, 10)
                },
                renderHTML: (attributes) => ({
                    width: attributes.width,
                }),
            },
            height: {
                default: null,
                parseHTML: (element) => {
                    const height = element.style.height || element.getAttribute('height') || null

                    return height === null ? null : parseInt(height, 10)
                },
                renderHTML: (attributes) => ({
                    height: attributes.height,
                }),
            },
            display: {
                default: ImageDisplay.BREAK_TEXT,
                parseHTML: (element) => {
                    const { display, cssFloat } = element.style
                    let dp =
                        element.getAttribute('data-display') ||
                        element.getAttribute('display')

                    if (dp) {
                        dp = /(inline|block|left|right)/.test(dp)
                            ? dp
                            : ImageDisplay.INLINE
                    } else if (cssFloat === 'left' && !display) {
                        dp = ImageDisplay.FLOAT_LEFT
                    } else if (cssFloat === 'right' && !display) {
                        dp = ImageDisplay.FLOAT_RIGHT
                    } else if (!cssFloat && display === 'block') {
                        dp = ImageDisplay.BREAK_TEXT
                    } else {
                        dp = ImageDisplay.BREAK_TEXT
                    }

                    return dp
                },
                renderHTML: (attributes) => ({
                    ['data-display']: attributes.display,
                }),

            },
        }
    },

    parseHTML() {
        const { video, image } = this.options
        const parseList: TagParseRule[] = []

        video && parseList.push({
            tag: 'video[src]:not([src^="data:"])',
            getAttrs: (el) => ({
                src: el.getAttribute('src'),
                type: 'video',
            }),
        })

        image && parseList.push({
            tag: 'img[src]:not([src^="data:"])',
            getAttrs: (el) => ({
                src: el.getAttribute('src'),
                type: 'image',
            }),
        })

        return parseList
    },

    renderHTML({ HTMLAttributes }) {
        const { 'data-type': type } = HTMLAttributes

        if (type === 'video') {
            return [ 'video', { controls: 'true', ...HTMLAttributes } ]
        }

        return ['img', mergeAttributes(this.options.HTMLAttributes, HTMLAttributes)]
    },

    addNodeView() {
        return VueNodeViewRenderer(NodeViewImage as any)
    },

    addCommands() {
        return {
            setMedia: options => ({ commands }) => {
                return commands.insertContent({
                    type: this.name,
                    attrs: options,
                })
            },
        }
    },

    addInputRules() {
        return [
            ...(this.options.image && [nodeInputRule({
                find: IMAGE_INPUT_REGEX,
                type: this.type,
                getAttributes: (match) => {
                    const [, , alt, src, title] = match

                    return {
                        src,
                        alt,
                        title,
                        type: 'image',
                    }
                },
            })] || []),
        ]
    },
})