import { Module } from 'vuex'
import { getField, updateField } from 'vuex-map-fields'
import { rgbToHex } from '@learningbank/lb-whitelabel-utils'
import CssVariablesUtils from '../utils/css-variables'
import Http from '@utils/Http'

import Themes from './themes.store'
import Fonts from './fonts.store'
import Glossary from './glossary.store'


const store: Module<WhitelabelsStoreState, any> = {
    namespaced: true,
    modules: {
        Themes,
        Fonts,
        Glossary,
    },

    state: {
        current: window.appConfig.whitelabel_config as unknown as WhitelabelConfigModel,
        whitelabel: null,
        whitelabels: [],
    },

    getters: {
        getField,
        currentHexList(state): string[] {
            const colors = state.current.colors
                .map(({ r, g, b }) => rgbToHex(r, g, b, true))

            return [...new Set(colors)]
        },
    },

    mutations: {
        updateField,

        SET_WHITELABELS(state, payload: WhitelabelConfigModel[]): void {
            state.whitelabels = payload
        },

        SET_WHITELABEL(state, payload?: WhitelabelConfigModel): void {
            state.whitelabel = payload ?? null
        },

        ADD_WHITELABEL(state, payload: WhitelabelConfigModel): void {
            state.whitelabels.push(payload)
        },

        UPDATE_WHITELABEL_LIST(state, payload: WhitelabelConfigModel): void {
            const index = state.whitelabels.findIndex(({ id }) => id === payload.id)

            if (payload.isDefault) {
                state.whitelabels.forEach((item) => item.isDefault = false)
            }

            state.whitelabels.splice(index, 1, payload)
        },

        DESTROY_WHITELABEL(state, configId: number): void {
            const index = state.whitelabels
                .findIndex(({ id }) => id === configId)

            state.whitelabels.splice(index, 1)
        },

        SYNC_CURRENT(state, whitelabelConfig?: WhitelabelConfigModel): void {
            if (whitelabelConfig)
                state.current = whitelabelConfig

            CssVariablesUtils.setConfig(state.current)

            // Set favicon for application
            document.querySelectorAll('link.favicon').forEach((tag) => {
                const href = `${LB_ENV.common.s3.buckets.public.url}/${state.current.logoSquare}`
                tag.setAttribute('href', href)
            })
        },
    },

    actions: {
        /**
         * Get all whitelabel configs for organization
         */
        async list({ commit }): Promise<WhitelabelConfigModel[]> {
            const { data } = await Http.api()
                .get(`api2/whitelabel`)

            commit('SET_WHITELABELS', data)

            return data
        },

        /**
         * Get whitelabel config by id
         */
        async get({ commit }, id: number): Promise<WhitelabelConfigModel> {
            const { data } = await Http.api()
                .get(`api2/whitelabel/${id}`)

            commit('SET_WHITELABEL', data)

            return data
        },

        /**
         * Create a new whitelabel
         */
        async create({ commit }, payload: Partial<WhitelabelConfigModel>): Promise<WhitelabelConfigModel> {
            const { data } = await Http.api()
                .post(`api2/whitelabel`, payload)

            commit('ADD_WHITELABEL', data)

            return data
        },

        /**
         * Update whitelabel config, will use current whitelabel
         * state by default or you can pass in a config to update.
         */
        async update(
            { commit, state },
            payload?: Partial<WhitelabelConfigModel>,
        ): Promise<WhitelabelConfigModel> {
            payload = payload ?? state.whitelabel ?? undefined

            if (!payload?.id)
                throw new Error('No given whitelabel config to update')

            const { data } = await Http.api()
                .patch(`api2/whitelabel/${payload.id}`, payload)

            commit('UPDATE_WHITELABEL_LIST', data)
            commit('SET_WHITELABEL', data)

            return data
        },

        /**
         * Delete whitelabel config by id
         */
        async destroy(
            { dispatch, commit },
            payload: {
                config: WhitelabelConfigModel;
                replacementConfig?: WhitelabelConfigModel;
            },
        ): Promise<void> {
            if (payload.config.divisions?.length) {
                const isSuccessful = await dispatch('departmentsWhitelabelUpdate', payload)

                if (!isSuccessful)
                    return Promise.reject(false)
            }


            await Http.api()
                .delete(`api2/whitelabel/${payload.config.id}`)

            commit('DESTROY_WHITELABEL', payload.config.id)
        },

        /**
         * Update whitelabel on multiple departments
         *
         * ? This can be improved when Departments has a patch request
         * ? Then we shouldn't need to fetch all departments and filter them
         */
        async departmentsWhitelabelUpdate(
            { dispatch },
            payload: {
                config: WhitelabelConfigModel;
                replacementConfig: WhitelabelConfigModel;
            },
        ): Promise<boolean> {
            if (!payload.replacementConfig)
                throw new Error('Missing replacement config for departments')

            await this.load('Departments')

            const departments: DivisionModel[] = await dispatch(
                'Departments/list',
                { simple: true },
                { root: true },
            )
            const departmentIds = (payload.config.divisions ?? []).map(({ id }) => id)
            const departmentPayloads = departments
                .filter(({ id }) => departmentIds.includes(id))
                .map((department) => ({
                    ...department,
                    whitelabel_config: {
                        id: payload.replacementConfig.id,
                        name: payload.replacementConfig.name,
                    },
                }))
            const promises = (departmentPayloads ?? []).map((department) =>
                dispatch(
                    'Departments/update',
                    { department },
                    { root: true },
                ))

            try {
                await Promise.all(promises)

                return true
            } catch (e) {
                return false
            }
        },

        /**
         * Set current whitelabel config or default to fetching config for user
         */
        async setCurrent(
            { dispatch, commit },
            whitelabelConfig?: WhitelabelConfigModel,
        ): Promise<WhitelabelConfigModel> {
            if (whitelabelConfig) {
                commit('SYNC_CURRENT', whitelabelConfig)

                return whitelabelConfig
            }

            const data = await dispatch('getCurrent')
            commit('SYNC_CURRENT', whitelabelConfig)

            return data
        },

        /**
         * Get current user whitelabel conifig
         */
        async getCurrent({ commit, dispatch }): Promise<WhitelabelConfigModel> {
            const data = await dispatch('getForUser')

            commit('SYNC_CURRENT', data)

            return data
        },

        /**
         * Get whitelabel config for user by id. Defaults to current logged in user.
         */
        async getForUser(_ctx, userId?: number): Promise<WhitelabelConfigModel> {
            const { data } = await Http.api()
                .get(`api2/whitelabel/current`, {
                    params: {
                        ...userId && { userId },
                    },
                })

            return data
        },
    },
}
export default store
