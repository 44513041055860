<template>
    <div class="tiptap-toolbar d-flex flex-wrap">
        <tiptap-button-group v-if="extensions.has(['heading', 'openAi', 'fontSize'])">
            <tiptap-dropdown-open-ai />
            <tiptap-dropdown-heading />
            <tiptap-dropdown
                #default="{ fontSize }"
                extension="fontSize"
                icon="text-size"
                label="Font size"
            >
                <tiptap-button
                    v-for="(size, key) in fontSize.sizes"
                    :key="key"
                    extension="fontSize"
                    :label="size.text"
                    command="setFontSize"
                    :command-params="size.value"
                    child
                />
            </tiptap-dropdown>
        </tiptap-button-group>
        <tiptap-button-group v-if="extensions.has(['bold', 'italic', 'underline', 'strikethrough'])">
            <tiptap-button
                icon="bold"
                label="Bold"
                extension="bold"
                command="toggleBold"
                shortcut="Mod+B"
            />
            <tiptap-button
                icon="italic"
                label="Italic"
                extension="italic"
                command="toggleItalic"
                shortcut="Mod+I"
            />
            <tiptap-button
                icon="underline"
                label="Underline"
                extension="underline"
                command="toggleUnderline"
                shortcut="Mod+U"
            />
            <tiptap-button
                icon="strikethrough"
                label="Strikethrough"
                extension="strike"
                command="toggleStrike"
                shortcut="Mod+Shift+X"
            />
        </tiptap-button-group>

        <tiptap-button-group v-if="extensions.has(['textColor', 'fontFamily'])">
            <tiptap-dropdown-text-color />
            <tiptap-dropdown
                #default="{ fontFamily }"
                extension="fontFamily"
                icon="font"
                label="Font family"
            >
                <tiptap-button
                    v-for="(font, key) in fontFamily.fonts"
                    :key="key"
                    :style="font.value ? `font-family: ${font.value}` : null"
                    :label="font.text"
                    extension="fontFamily"
                    command="setFontFamily"
                    :command-params="font.value"
                    child
                />
            </tiptap-dropdown>
        </tiptap-button-group>
        <tiptap-button-group v-if="extensions.has(['list', 'textAlign'])">
            <tiptap-dropdown
                extension="list"
                icon="list"
                label="Lists"
            >
                <tiptap-button
                    icon="list-ul"
                    :label="'Bullet list'"
                    extension="bulletList"
                    command="toggleBulletList"
                    shortcut="Mod+Shift+8"
                    child
                />
                <tiptap-button
                    icon="list-ol"
                    :label="'Ordered list'"
                    extension="orderedList"
                    command="toggleOrderedList"
                    shortcut="Mod+Shift+7"
                    child
                />
            </tiptap-dropdown>
            <tiptap-dropdown
                extension="textAlign"
                icon="align-left"
                label="Text align"
            >
                <tiptap-button
                    icon="align-left"
                    label="Align left"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="left"
                    shortcut="Mod+Shift+L"
                    child
                />
                <tiptap-button
                    icon="align-right"
                    label="Align right"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="right"
                    shortcut="Mod+Shift+R"
                    child
                />
                <tiptap-button
                    icon="align-center"
                    label="Align center"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="center"
                    shortcut="Mod+Shift+E"
                    child
                />
                <tiptap-button
                    icon="align-justify"
                    label="Align justify"
                    extension="textAlign"
                    command="setTextAlign"
                    command-params="justify"
                    shortcut="Mod+Shift+J"
                    child
                />
            </tiptap-dropdown>
        </tiptap-button-group>

        <tiptap-button-group v-if="extensions.has(['media', 'link', 'variable'])">
            <tiptap-dropdown-media />
            <tiptap-button-link
                extension="link"
                icon="link"
                label="Link"
            />
            <tiptap-dropdown
                #default="{ variable }"
                extension="variable"
                icon="brackets-curly"
                label="Variable"
            >
                <tiptap-button
                    v-for="name in variable.variables"
                    :key="name"
                    :label="`${name}`"
                    extension="variable"
                    command="setVariable"
                    :command-params="{ name }"
                    child
                />
            </tiptap-dropdown>
        </tiptap-button-group>
        <tiptap-button-group>
            <tiptap-button
                v-if="extensions.has('history')"
                icon="rotate-left"
                label="Undo"
                extension="history"
                command="undo"
                shortcut="Mod+Z"
            />
            <tiptap-button
                v-if="extensions.has('history')"
                icon="rotate-right"
                label="Redo"
                extension="history"
                command="redo"
                shortcut="Mod+Shift+Z"
            />
            <tiptap-button
                icon="eraser"
                label="Clear formatting"
                extension="core"
                command="unsetAllMarks"
            />
        </tiptap-button-group>
    </div>
</template>

<script lang="ts">
    import Vue, { VueConstructor } from 'vue'
    import { Inject } from './TiptapEditorProvider.vue'
    import TiptapButtonGroup from './TiptapButtonGroup.vue'
    import TiptapDropdown from './TiptapDropdown.vue'
    import TiptapButton from './TiptapButton.vue'
    import TiptapButtonLink from './TiptapButtonLink.vue'
    import TiptapDropdownHeading from './TiptapDropdownHeading.vue'
    import TiptapDropdownOpenAi from './TiptapDropdownOpenAi.vue'
    import TiptapDropdownTextColor from './TiptapDropdownTextColor.vue'
    import TiptapDropdownMedia from './TiptapDropdownMedia.vue'

    export default (Vue as VueConstructor<Vue & Inject>).extend({
        name: 'TiptapToolbar',
        components: {
            TiptapButtonGroup,
            TiptapDropdown,
            TiptapButton,
            TiptapButtonLink,
            TiptapDropdownHeading,
            TiptapDropdownOpenAi,
            TiptapDropdownTextColor,
            TiptapDropdownMedia,
        },
        inject: ['editor', 'extensions'],
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    .tiptap-toolbar {
        column-gap: spacer(2);
    }
</style>