
<template>
    <node-view-wrapper
        as="span"
        class="media-view"
        :class="`media-view--${display}`"
    >
        <div
            data-drag-handle
            class="media-view__body"
            :class="{
                'media-view__body--focused': selected,
            }"
        >
            <img
                v-if="node.attrs.type === 'image'"
                ref="resizableMedia"
                class="media-view__body__item"
                v-bind="node.attrs"
            >
            <video
                v-if="node.attrs.type === 'video'"
                ref="resizableMedia"
                class="media-view__body__item"
                v-bind="node.attrs"
                controls="true"
            />
            <div
                v-if="editor?.isEditable"
                v-show="selected || resizing"
                class="media-resizer"
            >
                <span
                    v-for="direction in resizeDirections"
                    :key="direction"
                    class="media-resizer__handler"
                    :class="`media-resizer__handler--${direction}`"
                    :title="direction === 'br' ? $t('TIPTAP.MEDIA.INFO.RESIZE_MODIFIER') : null"
                    @mousedown="onMouseDown($event, direction)"
                />
            </div>
        </div>

        <!-- Popover menu -->
        <b-popover
            v-if="editor?.isEditable && $refs.resizableMedia"
            :target="$refs.resizableMedia"
            :show="selected && !resizing"
            placement="bottom"
            triggers="manual"
            no-fade
        >
            <div class="d-flex align-items-center g-2">
                <span
                    v-for="displayItem in displayOpts"
                    :key="displayItem.name"
                >
                    <btn
                        v-tooltip="$t(`TIPTAP.MEDIA.DISPLAY.${displayItem.name.toUpperCase()}`)"
                        emphasis="low"
                        :icon="['fac', displayItem.icon]"
                        :class="{ 'text-primary': node.attrs.display === displayItem.name }"
                        pill
                        @click="updateAttributes({ display: displayItem.name })"
                    />
                </span>
                <span>
                    <b-dropdown
                        right
                        variant="simple-default"
                        toggle-class="text-reset btn-icon rounded-pill"
                        boundary="window"
                    >
                        <template #button-content>
                            <fa :icon="['fal', 'ellipsis-v']" />
                        </template>
                        <b-dropdown-item-button @click="updateAttributes({ width: null, height: null })">
                            {{ $t('TIPTAP.MEDIA.SIZE.RESET') }}
                        </b-dropdown-item-button>
                        <b-dropdown-item-button
                            variant="danger"
                            @click="removeMedia"
                        >
                            {{ $t('TERMS.DELETE') }}
                        </b-dropdown-item-button>
                    </b-dropdown>
                </span>
            </div>
            <div
                v-if="node.attrs.type === 'image'"
                class="mt-3"
            >
                <input-field
                    :value="node.attrs.title"
                    :label="$t('TIPTAP.MEDIA.FIELD.TITLE')"
                    @blur="({ target }) => updateAttributes({ title: target.value })"
                />
                <input-field
                    :value="node.attrs.alt"
                    :label="$t('TIPTAP.MEDIA.FIELD.ALT')"
                    @blur="({ target }) => updateAttributes({ alt: target.value })"
                />
            </div>
        </b-popover>
    </node-view-wrapper>
</template>

<script lang="ts">
    import { defineComponent } from 'vue'
    import { NodeViewWrapper, nodeViewProps } from '@tiptap/vue-2'
    import { BPopover, BDropdown, BDropdownItemButton } from 'bootstrap-vue'
    import InputField from '@common/Forms/InputField.vue'

    const MIN_SIZE = 20
    const MAX_SIZE = 100000
    const initialResizerState = () => ({
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        dir: '',
    })

    const DISPLAY_OPTIONS = [
        { name: 'inline', icon: 'img-inline' },
        { name: 'block', icon: 'img-align-left' },
        { name: 'left', icon: 'img-float-left' },
        { name: 'right', icon: 'img-float-right' },
    ]

    export default defineComponent({
        components: {
            NodeViewWrapper,
            BPopover,
            BDropdown,
            BDropdownItemButton,
            InputField,
        },

        props: nodeViewProps,

        data() {
            return {
                resizing: false,
                maxSize: { w: MAX_SIZE, h: MAX_SIZE },
                originalSize: { w: 0, h: 0 },
                resizerState: initialResizerState(),

                resizeDirections: ['tl', 'tr', 'bl', 'br'],
                displayOpts: DISPLAY_OPTIONS,
            }
        },

        computed: {
            display(): string {
                return this.node.attrs.display
            },
        },

        methods: {
            selectImage() {
                this.editor?.commands.setNodeSelection(this.getPos())
            },

            onMouseDown(e: MouseEvent, direction: string) {
                e.preventDefault()
                e.stopPropagation()

                this.resizerState.x = e.clientX
                this.resizerState.y = e.clientY

                const originalWidth = (this.$refs.resizableMedia as HTMLImageElement | HTMLVideoElement).width
                const originalHeight = (this.$refs.resizableMedia as HTMLImageElement | HTMLVideoElement).height
                const aspectRatio = originalWidth / originalHeight

                let { width, height } = this.node.attrs
                const maxWidth = this.maxSize.w

                if (width && !height) {
                    width = width > maxWidth ? maxWidth : width
                    height = Math.round(width / aspectRatio)
                } else if (height && !width) {
                    width = Math.round(height * aspectRatio)
                    width = width > maxWidth ? maxWidth : width
                } else if (!width && !height) {
                    width = originalWidth > maxWidth ? maxWidth : originalWidth
                    height = Math.round(width / aspectRatio)
                } else {
                    width = width > maxWidth ? maxWidth : width
                }

                this.resizerState.w = width
                this.resizerState.h = height
                this.resizerState.dir = direction

                this.resizing = true

                this.onEvents()
            },

            onMouseMove(e: MouseEvent) {
                e.preventDefault()
                e.stopPropagation()

                if (!this.resizing) return

                const { x, y, w, h, dir } = this.resizerState

                const dx = (e.clientX - x) * (/l/.test(dir) ? -1 : 1)
                const dy = (e.clientY - y) * (/t/.test(dir) ? -1 : 1)


                const newWidth = this.clamp(w + dx, MIN_SIZE, this.maxSize.w)
                let newHeight = h + dy

                if (e.shiftKey) {
                    newHeight = newWidth / (w / h)
                } else {
                    newHeight = Math.max(h + dy, MIN_SIZE)
                }

                this.updateAttributes({
                    width: newWidth,
                    height: newHeight,
                })
            },

            onMouseUp(e: MouseEvent) {
                e.preventDefault()
                e.stopPropagation()

                if (!this.resizing) return

                this.resizing = false
                this.resizerState = initialResizerState()

                this.offEvents()
                this.selectImage()
            },

            onEvents(): void {
                window.addEventListener('mousemove', this.onMouseMove, true)
                window.addEventListener('mouseup', this.onMouseUp, true)
            },

            offEvents(): void {
                window.removeEventListener('mousemove', this.onMouseMove, true)
                window.removeEventListener('mouseup', this.onMouseUp, true)
            },

            clamp(val: number, min: number, max: number): number {
                if (val < min) return min
                if (val > max)  return max

                return val
            },

            removeMedia(): void {
                this.extension?.options.onMediaRemoved?.(this.node.attrs.src)
                this.editor?.commands.deleteSelection()
            },

        },

    })

</script>

<style lang="scss" scoped>
    @import "@scss/vue.scss";

    $img-margin: spacer(2);

    .media-view {
        display: inline-block;
        float: none;
        line-height: 0;
        margin: $img-margin 0;
        max-width: 100%;
        user-select: none;
        vertical-align: baseline;

        &--inline {
            margin-left: $img-margin;
            margin-right: $img-margin;
        }

        &--block {
            display: block;
        }

        &--left {
            float: left;
            margin-left: 0;
            margin-right: $img-margin;
        }

        &--right {
            float: right;
            margin-left: $img-margin;
            margin-right: 0;
        }

        &__body {
            position: relative;
            clear: both;
            display: inline-block;
            max-width: 100%;
            outline: 2px solid transparent;
            transition: all 200ms ease-in;

            &:hover {
                outline-color: $link-color;
            }

            &--focused:hover,
            &--resizing:hover {
                outline-color: transparent;
            }

            &__item {
                cursor: pointer;
                pointer-events: none;
                margin: 0 !important;
            }
            &--focused &__item {
                pointer-events: all;
            }
        }
    }

    .media-resizer {
        position: absolute;
        border: $border-width * 2 solid $link-color;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        pointer-events: none;

        // Resize handles
        &__handler {
            $handle-size: 1rem;
            position: absolute;
            background-color: $link-color;
            border: $border-width *2 solid $white;
            border-radius: 100%;
            display: block;
            height: $handle-size;
            width: $handle-size;
            z-index: 2;
            pointer-events: auto;

            &--tl {
                cursor: nw-resize;
                top: -#{$handle-size / 2};
                left: -#{$handle-size / 2};
            }
            &--tr {
                cursor: ne-resize;
                top: -#{$handle-size / 2};
                right: -#{$handle-size / 2};
            }
            &--bl {
                cursor: sw-resize;
                bottom: -#{$handle-size / 2};
                left: -#{$handle-size / 2};
            }
            &--br {
                cursor: se-resize;
                bottom: -#{$handle-size / 2};
                right: -#{$handle-size / 2};
            }

        }
    }
</style>
