import Vue, { VueConstructor } from 'vue'
import { create } from 'vue-modal-dialogs'
import { CustomDialog } from './typings'
import Dialog from './Dialog.vue'
import { $localStorage } from '@plugins/storage'

export const STORAGE_PREFIX = 'dialogDismissed-'

export const dialog: CustomDialog = {
    modal(modalInstance: any, props = {}): any {
        const modal = create(modalInstance)

        return modal(props)
    },

    alert(options) {
        if (this._preventDialog(options.storageKey)) {
            return Promise.resolve({ storageKey: options.storageKey, dismissed: true })
        }

        const modal = create(Dialog)

        return modal({ options, type: 'ALERT' })
    },

    confirm(options) {
        if (this._preventDialog(options.storageKey)) {
            return Promise.resolve({ storageKey: options.storageKey, dismissed: true })
        }

        const modal = create(Dialog)

        return modal({ options, type: 'CONFIRM' })
    },

    _preventDialog(storageKey): boolean {
        if (storageKey) {
            const storage = $localStorage.get(STORAGE_PREFIX + storageKey)
            if (storage !== null)
                return true
        }

        return false
    },
}

const DialogPlugin = {
    install(Vue: VueConstructor): void {
        Vue.prototype.$dialog = dialog
    },
}

Vue.use(DialogPlugin)
