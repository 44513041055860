import Vue from 'vue'
import { Module } from 'vuex'
import { make } from 'vuex-pathify'
import Http from '@utils/Http'


const state: WhitelabelsGlossaryStoreState = {
    glossaries: [],
}

const store: Module<WhitelabelsGlossaryStoreState, any> = {
    namespaced: true,
    state,

    mutations: {
        ...make.mutations(state),

        ADD_GLOSSARY(state, glossary: WhitelabelGlossaryModel): void {
            state.glossaries.push(glossary)
        },

        UPDATE_GLOSSARY(state, payload: WhitelabelGlossaryReplacePayload): void {
            const index = state.glossaries.findIndex((item) => item.id === payload.glossaryId)
            Vue.set(state.glossaries, index, payload.glossary)
        },

        REMOVE_GLOSSARY(state, glossaryId: number): void {
            const index = state.glossaries.findIndex((item) => item.id === glossaryId)

            if (index >= 0)
                state.glossaries.splice(index, 1)
        },
    },

    actions: {
        /**
         * Get all glossaries
         */
        async get({ commit }): Promise<WhitelabelGlossaryModel[]> {
            const { data } = await Http.api().get(`api2/glossary`)

            commit('SET_GLOSSARIES', data)

            return data
        },

        async create(
            { commit },
            payload: WhitelabelGlossaryPostPayload,
        ): Promise<WhitelabelGlossaryModel[]> {
            const { data } = await Http.api().post(`api2/glossary`, payload.glossary)

            commit('ADD_GLOSSARY', data)

            return data
        },

        async replace(
            { commit },
            { glossaryId, glossary }: WhitelabelGlossaryReplacePayload,
        ): Promise<WhitelabelGlossaryModel[]> {
            if (!glossaryId)
                throw new Error(`[Whitelabels/Glossary/replace]: Missing glossaryId!`)

            const { data } = await Http.api().post(`api2/glossary/${glossaryId}/replace`, glossary)

            commit('UPDATE_GLOSSARY', {
                glossaryId,
                glossary: data,
            })

            return data
        },

        /**
         * Delete glossary
         */
        async destroy({ commit }, payload: WhitelabelGlossaryModel): Promise<void> {
            if (!payload.id)
                throw new Error(`[Whitelabels/Glossary/destroy]: Missing id!`)

            await Http.api().delete(`api2/glossary/${payload.id}`)

            commit('REMOVE_GLOSSARY', payload.id)
        },
    },
}

export default store
