<template>
    <component
        :is="modal ? 'base-modal' : 'div'"
        :title="title"
        size="lg"
        centered
        scrollable
        dialog-modal-class="resource-selection-modal"
    >
        <!-- Resource selection tabs -->
        <b-tabs
            v-if="!shouldCrop"
            v-model="tabIndex"
            class="h-100"
            :vertical="isVertical"
            :nav-wrapper-class="tabsNavWrapperClass"
            nav-class="position-sticky top-0 g-3 g-md-0"
            align="center"
            pills
            lazy
            @activate-tab="resetSelection"
        >
            <b-tab
                v-if="preview"
                :title="$t('TOOLTIP.PREVIEW')"
            >
                <image-lazy
                    class="img-fluid rounded"
                    :src="preview"
                />
            </b-tab>
            <b-tab
                :title="$t('TERMS.UPLOAD')"
                class="h-100"
            >
                <dropzone
                    #default="{ errors, hasErrors, openDialog, dragOver }"
                    class="h-100"
                    :rules="rules"
                    :multiple="multiple"
                    hide-errors
                    clean
                    @input="onSelect"
                >
                    <card
                        emphasis="none"
                        border="dashed"
                        class="h-100 transition-all"
                        :class="{ 'border-primary': dragOver }"
                        body-class="d-flex flex-column align-items-center justify-content-center"
                        hover-class="bg-off-white border-primary"
                        :variant="dragOver ? 'primary' : 'default'"
                    >
                        <!-- Image preview -->
                        <div
                            v-if="fileBlob"
                            class="w-50 mb-3 border rounded p-2"
                        >
                            <img
                                :src="fileBlob"
                                class="img-fluid d-block mx-auto"
                            >
                        </div>

                        <!-- Illustration -->
                        <inline-svg
                            v-else-if="icon"
                            class="w-25 mb-3 text-gray"
                            :src="icon"
                        />

                        <!-- Dropzone message -->
                        <div class="text-muted">
                            {{ $tc('UPLOADER.DROP_FILE', 1) }},
                            <i18n path="TERMS.OR" />&nbsp;
                            <i18n
                                class="text-lowercase"
                                href="#"
                                tag="a"
                                path="TERMS.BROWSE"
                                @click.prevent="openDialog"
                            />
                        </div>

                        <!-- Error display -->
                        <div
                            v-if="hasErrors"
                            class="position-relative w-100"
                        >
                            <small
                                class="centerer top-3 text-danger w-100 text-center"
                                v-text="errors[0]"
                            />
                        </div>
                    </card>
                </dropzone>
            </b-tab>

            <!-- Unsplash image selection -->
            <b-tab
                v-if="unsplash"
                :title="$t('IMAGE.LIBRARY')"
            >
                <unsplash-select
                    @selected="onSelect"
                />
            </b-tab>

            <!-- Giphy image selection -->
            <b-tab
                v-if="giphy"
                :title="$t('IMAGE.GIF_LIBRARY')"
            >
                <giphy-select
                    @selected="onSelect"
                />
            </b-tab>
        </b-tabs>

        <!-- Image cropper -->
        <image-cropper
            v-else
            ref="cropper"
            :class="{ 'mh-100': modal }"
            :file="files[0]"
            :local-actions="false"
            :crop-container-style="{ maxHeight: modal ? '58vh' : undefined }"
            v-bind="imageCropperProps"
        />

        <!-- Modal footer -->
        <template #footer>
            <btn
                v-if="shouldCrop"
                :label="$t('IMAGE.SELECT_PICTURE')"
                class="mr-auto"
                :icon="['fal', 'arrow-left']"
                @click="resetSelection"
            />
            <btn
                variant="default"
                emphasis="low"
                :label="$t('TERMS.CANCEL')"
                @click="cancel"
            />
            <btn
                v-if="canSubmit"
                variant="primary"
                :label="$t('TERMS.SAVE')"
                @click="submit"
            />
        </template>
    </component>
</template>

<script lang="ts">
    import Vue, { PropType, VueConstructor } from 'vue'
    import { BTabs, BTab } from 'bootstrap-vue'
    import { DialogComponent } from 'vue-modal-dialogs'
    import UnsplashSelect from '@common/Integrations/UnsplashSelect.vue'
    import GiphySelect from '@common/Integrations/GiphySelect.vue'
    import ImageCropper from '@common/Images/ImageCropper.vue'
    import Dropzone from '@common/components/Dropzone.vue'
    import Card from '@common/Card/Card.vue'
    import ImageLazy from '@common/Images/ImageLazy.vue'
    import BaseModal from './BaseModal.vue'
    import { TranslateResult } from 'vue-i18n'

    import { ICON_MAP } from '@common/Images/utils'

    interface Refs {
        $refs: {
            cropper: InstanceType<typeof ImageCropper>;
        };
    }

    /**
     * This modal supports selecting image files from various sources.
     * You can allow image cropping as well before returning the image
     *
     * This modal is meant to be used with our promise based modal implementation.
     * The promise will return the selected File object
     */
    export default (Vue as VueConstructor<Vue & Refs & DialogComponent<File | File[] | null>>).extend({
        components: {
            BaseModal,
            BTabs,
            BTab,
            UnsplashSelect,
            GiphySelect,
            ImageCropper,
            Dropzone,
            Card,
            ImageLazy,
        },

        props: {
            modal: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Allowed file types to select
             */
            type: {
                type: String as PropType<string>,
                default: 'image',
                validator: (value: string): boolean => ['image', '*'].includes(value),
            },

            /**
             * Image selectors height in pixels (keeps modal from jumping)
             */
            height: {
                type: [Number, String] as PropType<number | string>,
                default: 500,
                validator: (value): boolean => !isNaN(Number(value)),
            },

            /**
             * Allow selected image to be cropped before selection
             */
            crop: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Allow selecting images from Unsplash integration
             */
            unsplash: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Allow selecting images from GIPHY integration
             */
            giphy: {
                type: Boolean as PropType<boolean>,
                default: true,
            },

            /**
             * Props passed on to the internal `ImageCropper` component
             */
            imageCropperProps: {
                type: Object as PropType<object>,
                default: (): object => ({}),
            },

            multiple: {
                type: Boolean as PropType<boolean>,
                default: false,
            },

            /**
             * Preview image, when prop is used we create a new tab called preview
             * useful for showing the current value of the field that allows image uploads
             */
            preview: {
                type: String as PropType<string>,
                default: null,
            },
        },

        data() {
            return {
                tabIndex: 0,
                files: [] as File[],
            }
        },

        computed: {
            icon(): SVGElement | null {
                return ICON_MAP[this.type] || null
            },

            rules(): string | null {
                if (this.type === 'image')
                    return 'image|size:2000'

                return null
            },

            isVertical(): boolean {
                return this.$device.isDesktop
            },

            tabsNavWrapperClass(): object {
                return {
                    'col-lg-3': true,
                    'mb-3': true,
                    'd-none': !this.unsplash && !this.giphy,
                }
            },

            shouldCrop(): boolean {
                if (this.multiple || this.type !== 'image')
                    return false

                const excludeList = ['image/gif', 'image/webp', 'image/svg+xml']

                return this.crop && !!this.files.length && !excludeList.includes(this.files[0].type)
            },

            title(): TranslateResult {
                return this.shouldCrop
                    ? this.$t('TERMS.EDIT')
                    : this.$t('TERMS.SELECT')
            },

            canSubmit(): boolean {
                return !!this.files.length
            },

            fileBlob(): string | null {
                if (this.multiple)
                    return null

                return this.files.length
                    ? URL.createObjectURL(this.files[0])
                    : null
            },
        },

        methods: {
            onSelect(files: File | File[]): void {
                this.files = Array.isArray(files)
                    ? files
                    : [files]

                if (this.multiple || this.type !== 'image')
                    this.submit()
            },

            resetSelection(): void {
                this.files = []
            },

            cancel(): void {
                this.$error('cancel')
            },

            async submit(): Promise<void> {
                if (this.multiple)
                    return this.$close(this.files)

                const file = this.shouldCrop
                    ? await this.$refs.cropper.crop()
                    : this.files[0]

                return this.$close(file)
            },
        },
    })
</script>

<style lang="scss" scoped>
    @import '@scss/vue.scss';

    ::v-deep .resource-selection-modal {
        .modal-content {
            @include media-breakpoint-up(md) {
                height: 75vh !important;
            }
        }

        .modal-body {
            padding-top: 0;
            padding-bottom: 0;
            margin: spacer(4) 0;
        }
    }

    ::v-deep .tabs {
        .nav-item .nav-link {
            color: $text-muted;
            background: inherit;

            &.active {
                color: $body-color;
                text-decoration: underline;
                text-decoration-color: whitelabel-color('primary');
                text-decoration-thickness: $border-width * 2;
                text-underline-offset: .5rem;
            }
        }
    }
</style>
