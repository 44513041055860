import moment from 'moment-timezone'
import { i18n } from '@plugins/i18n'

function getFormats(format: string | string[], fallbacks: string[]): { startFormat: string; endFormat: string } {
    return {
        startFormat: Array.isArray(format) ? format[0] : format || fallbacks[0],
        endFormat: Array.isArray(format) ? format[1] : format || fallbacks[1],
    }
}

function formatting(
    date: Date | string | moment.Moment,
    format = 'YYYY-MM-DD HH:mm',
    timezone?: string,
): string {
    if (!timezone)
        return moment(date).format(format)

    return moment(date)
        .tz(timezone)
        .format(format)
}

export function formatDate(value: string | Date, format = 'll', timezone?: string): string {
    if (value)
        return formatting(value, format, timezone)

    return value
}

export function formatDateRange(
    value: string | string[],
    format: string | [string, string] = 'LL',
    timezone?: string,
): string {
    const dates = Array.isArray(value)
        ? value.map((d) => moment(d))
        : [moment(value)]

    const startDate = moment.min(dates)
    const endDate = moment.max(dates)

    let [startFormat, endFormat] = Array.isArray(format)
        ? format
        : [format]

    // If only one date or two identical, return only one date
    if (dates.length === 1 || startDate.isSame(endDate, 'day'))
        return formatting(startDate, startFormat, timezone)

    if (startDate.year() !== endDate.year())
        return `${formatting(startDate, startFormat, timezone)} - ${formatting(endDate, startFormat, timezone)}`

    if (startDate.month() === endDate.month())
        ({ startFormat, endFormat } = getFormats(format, ['D.', 'D. MMMM YYYY']))
    else
        ({ startFormat, endFormat } = getFormats(format, ['D. MMMM', 'D. MMMM YYYY']))

    return `${formatting(startDate, startFormat, timezone)} - ${formatting(endDate, endFormat, timezone)}`
}

export function formatTime(value: string, format = 'HH:mm', timezone?: string): string {
    if (value)
        return formatting(value, format, timezone)

    return value
}

export function formatTimeRange(value: string | [string, string], format = 'HH:mm', timezone?: string): string {
    const times = Array.isArray(value)
        ? value
        : [value]

    const [startTime, endTime] = times

    // If only one time or two identical, return only one time
    if (times.length === 1 || moment(startTime).isSame(endTime))
        return formatting(startTime, format, timezone)

    return `${formatting(startTime, format, timezone)} - ${formatting(endTime, format, timezone)}`
}

/**
* Add or subtract days from a date
*
* @param days number
* @return { Date }
*/
export function dateSumDays(days: number, date: string | Date = new Date()): Date {
    date = new Date(date)
    date.setDate(date.getDate() + days)

    return date
}

export function getYear(years: number): number {
    const date = new Date()

    return date.getFullYear() + years
}

export function getLabelLastDays(days: number): [string, number, { days: number }] {
    return ['TERMS.LAST_DAYS', days, { days }]
}

export function daysBetweenDates(start: Date, end: Date): number {
    if (typeof start === 'string') start = new Date(start)
    if (typeof end === 'string') end = new Date(end)
    const difference = end.getTime() - start.getTime()

    return Math.ceil(difference / (1000 * 3600 * 24))
}

/**
 * @deprecated Use date() instead
 */
export function dateToDisplay(date: Date): string {
    return moment(date).format('DD MMM YYYY')
}

/**
 * @deprecated Use date() instead
 */
export function dateToString(date: Date): string {
    return moment(date).format('YYYY-MM-DD')
}

/**
 * Time since and until in human readable format
 *
 * @param dateString Valid date string
 * @param prefix include prefix (+/-) to indicate future or past
 * @returns formatted string
 */
export function timeSinceOrUntil(dateString: string, prefix = false) {
    const now = new Date()
    const targetDate = new Date(dateString)

    // Calculate the time difference
    const timeDiff = targetDate.getTime() - now.getTime() // Difference in milliseconds
    const totalDaysDiff = Math.round(Math.abs(timeDiff) / (1000 * 60 * 60 * 24)) // Total difference in days

    // Initialize years, months, and days
    let yearsDiff = Math.floor(totalDaysDiff / 365)
    const monthsDiff = Math.floor((totalDaysDiff % 365) / 30)
    const daysDiff = totalDaysDiff % 30

    // Round years
    if (yearsDiff > 0 && (totalDaysDiff % 365) >= 182) {
        yearsDiff++
    }

    // Determine the sign for output
    const sign = timeDiff > 0 ? '+' : '-'

    // Build the output based on the calculated differences
    let result = ''

    if (yearsDiff > 0) {
        result = `${yearsDiff} ${i18n.tc('TERMS.YEARS_PLURAL', yearsDiff)}`
    } else if (monthsDiff > 0) {
        result = `${monthsDiff} ${i18n.tc('TERMS.MONTHS_PLURAL', monthsDiff)}`
    } else {
        result = `${daysDiff} ${i18n.tc('TERMS.DAYS_PLURAL', daysDiff || 2)}`
    }

    return (prefix
        ? `${sign}${result}`
        : result).toLowerCase()
}
