import { Extension } from '@tiptap/core'
import { Table, TableOptions as TableOptionsDefault } from '@tiptap/extension-table'
import { TableHeader } from '@tiptap/extension-table-header'
import { TableRow } from '@tiptap/extension-table-row'
import { TableCell } from '@tiptap/extension-table-cell'
import { Gapcursor } from '@tiptap/extension-gapcursor'

export type TableOptions = Pick<TableOptionsDefault, 'resizable' | 'allowTableNodeSelection' | 'lastColumnResizable'>

export default Extension.create<TableOptions>({
    addOptions() {
        return {
            resizable: true,
            allowTableNodeSelection: false,
            lastColumnResizable: true,
        }
    },

    addExtensions() {
        return [
            Table.configure(this.options),
            TableHeader,
            TableRow,
            TableCell,
            Gapcursor,
        ]
    },
})
